<template>
    <div>
        <v-container fluid>
            <v-alert
                dense
                :type="alert.type"
                timeout="5000"
                v-if="alert.message">
                {{ alert.message }}
            </v-alert>
            <v-toolbar
                color="blue"
                dark
            >
                <v-toolbar-title>
                    <v-breadcrumbs :items="Path">
                        <template v-slot:item="{ index, item }">
                        <v-breadcrumbs-item
                            :disabled="index == 0"
                            @click="viewFolder(item)""
                        >
                            {{ item === '/' ? 'Root': item }}
                        </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        label="Search"
                        placeholder="Type to search..."
                        solo
                        hide-details
                        prepend-icon="mdi-magnify"
                        single-line
                        dense
                        clearable
                    />
            </v-toolbar>
            <v-row dense>
                <v-col
                v-for="f in Fs"
                :key="f.delete_hash"
                cols="auto"
                >
                <v-card>
                    <v-card-title>
                        <v-img
                            :src="f.ext ? f.ext === 'apk' ? '/images/files/apk.png':'/images/files/file.png' : '/images/files/folder.png'"
                            class="white--text align-end mx-1"
                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                            height="32px"
                            width="32px"
                            max-width="32px"
                            max-height="32px"
                        />
                        <span v-text="f.file_name" />
                    </v-card-title>
                        <v-card-actions>
                            <template v-if="!f.directory">
                                <span v-text="f.hash" class="mx-1" />
                                <span v-text="parseSize(f.size)" class="mx-1" />
                            </template>
                            <v-spacer></v-spacer>
                            <v-btn icon v-if="!f.directory && isPick" @click="Pick(f)">
                                <v-icon>mdi-eye-arrow-right</v-icon>
                            </v-btn>
                            <v-btn icon v-if="f.directory" @click="viewFolder(f.file_name)">
                                <v-icon>mdi-eye-arrow-right</v-icon>
                            </v-btn>
                            <v-btn icon v-if="f.delete_hash" @click="askDelete(f.delete_hash)" class="red--text">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-card-actions>
                </v-card>
                </v-col>
            </v-row>
            <Loading :status="Overlay" />
        </v-container>
    </div>
</template>

<script>
//import Alert from '../helpers/notice';
import Loading from '../components/Loading.vue';
import Loader from '../components/Loader.vue';
import {mapActions, mapState} from "vuex";
import {fetchData, parseSize} from "../helpers";
export default {
  name: "FileServe",
    components: {
        Loading,
        Loader,
    },
    data(){
        return {
            Overlay: false,
            search: "",
            fs:[],
            top: '/',
            isPick: false,
        }
    },
    created() {
        if (this.$listeners.onPick) {
            this.isPick = true;
        }
    },
    computed: {
        ...mapState('auth', ['status']),
        ...mapState({
            alert: state => state.alert
        }),
        Path(){
            let bc = this.top ? this.top.split("/").filter(s=>!!s):[];
            if(bc.length > 0) {
                return ["/"].concat(bc)
            }
            return ["/"]
        },
        Fs(){
            return this.fs.filter(f=>f.file_name.indexOf(this.search) > 0 || (f.hash && f.hash === this.search.toString().toLowerCase()) || this.search==="" || this.search === null || this.search === undefined)
        }
    },
    methods:{
        parseSize,
        ...mapActions('auth', ['login', 'logout']),
        ...mapActions('alert', ['success', 'error']),
        Pick(f){
            f.file_name = `${this.top}/${f.file_name}`;
            this.$emit('onPick', f);
        },
        async askDelete(delete_hash, is_directory){
            const answer = await this.$confirm(`Do you really want to delete this ? This data can't restore !`,{ title: 'Warning' });
            if(answer){
                this.Overlay = true;
                const cmd = is_directory ? "d":"f";
                return fetchData(`${this.$API}/api/fm/r/${cmd}/${delete_hash}`, undefined, "DELETE").then(()=>{},(err)=>{
                    if(err === 401){
                        this.logout();
                    }else{
                        this.error('SERVER ERROR !!!');
                    }
                }).finally(()=>{
                    this.Overlay = false;
                    this.fetchFs();
                });
            }
        },
        viewFolder(path){
            if(path === "") path = "/";
            this.top = `/${path}`;
            this.fetchFs();
        },
        fetchFs(){
            this.Overlay = true;
            return fetchData(`${this.$API}/api/fm/fs?path=${this.top}`).then((json)=>{
                this.fs = json.files;
                if(json.top) this.top = json.top;
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    this.error('SERVER ERROR !!!');
                }
            }).finally(()=>this.Overlay = false);
        },
    },
    mounted() {
        this.fetchFs();
    }
}
</script>